/* Colors */
:root {
    --primary-color: #0079d3;
    --secondary-color: #f0f0f0;
    --background-color: #ffffff;
    --text-color: #1a1a1b;
    --text-secondary-color: #878a8c;
}

/* User page styles */
.user-page {
    max-width: 800px;
    margin: 0 auto;
    background-color: var(--background-color);
    padding: 20px;
}

.header {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    margin-top: 5vh;
}

.avatar img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-right: 30px;
}

.username {
    margin: 0;
    font-size: 32px;
    font-weight: bold;
    color: var(--text-color);
}

.stats {
    display: flex;
    margin-top: 10px;
}

.stat-item {
    margin-right: 30px;
    font-size: 16px;
    color: var(--text-secondary-color);
}

.stat-item:last-child {
    margin-right: 0;
}

.stat-item .count {
    font-size: 24px;
    font-weight: bold;
    color: var(--text-color);
}

.stat-item .label {
    display: block;
    font-size: 14px;
    color: var(--text-secondary-color);
}

.bio {
    margin-top: 30px;
    font-size: 16px;
    line-height: 1.5;
    color: var(--text-color);
}

.bio > p {
  overflow-wrap: anywhere;
}

.content {
    /* border: 1px solid var(--secondary-color); */
    background-color: var(--background-color);
    padding: 20px;
}

.tabs {
    padding-bottom: 10px;
}

.tabs ul {
    list-style: none;
    margin: 0;
    padding: 0;
    border-bottom: 1px solid var(--secondary-color);
}

.tabs ul li {
    display: inline-block;
    margin-right: 20px;
    font-size: 18px;
    font-weight: bold;
}

.tabs ul li:last-child {
    margin-right: 0;
}

.tabs ul li a {
    color: var(--text-color);
    text-decoration: none;
}

.tabs ul li.active a {
    color: var(--primary-color);
    border-bottom: 2px solid var(--primary-color);
}

.post-list {
    margin-top: 20px;
}

.post {
    margin-bottom: 30px;
    border-bottom: 1px solid var(--secondary-color);
    padding-bottom: 20px;
}

.post:last-child {
    margin-bottom: 0;
}

.post-info {
    font-size: 14px;
    color: var(--text-secondary-color);
    margin-bottom: 10px;
}

.post-info a {
    color: var(--primary-color);
    text-decoration: none;
}

.post-info a:hover {
    text-decoration: underline;
}

.post-info .dot {
    display: inline-block;
    width: 4px;
    height: 4px;
    background-color: var(--text-secondary-color);
    margin: 0 5px;
    border-radius: 50%;
}

.post-title {
    margin-top: 0;
    margin-bottom: 5px;
    font-size: 24px;
    font-weight: bold;
    color: var(--text-color);
}

.post-title a {
    color: var(--text-color);
    text-decoration: none;
}

.post-title a:hover {
    text-decoration: underline;
}

.post-body {
    font-size: 16px;
    color: var(--text-color);
    line-height: 1.5;
}

.post-footer {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.post-actions {
    display: flex;
    align-items: center;
}

.post-actions button {
    background-color: transparent;
    border: none;
    font-size: 16px;
    color: var(--text-secondary-color);
    margin-right: 10px;
    cursor: pointer;
}

.post-actions button:hover {
    text-decoration: underline;
}

.post-actions button:focus {
    outline: none;
}

.post-actions button.active {
    color: var(--primary-color);
    text-decoration: underline;
}

.post-votes {
    display: flex;
    align-items: center;
}

.post-votes button {
    background-color: transparent;
    border: none;
    font-size: 16px;
    color: var(--text-secondary-color);
    margin-right: 10px;
    cursor: pointer;
}

.post-votes button:hover {
    text-decoration: underline;
}

.post-votes button:focus {
    outline: none;
}

.post-votes button.active {
    color: var(--primary-color);
}

.post-votes .count {
    font-size: 16px;
    color: var(--text-color);
    font-weight: bold;
    margin-right: 10px;
}

.post-votes .icon {
    font-size: 20px;
    color: var(--text-secondary-color);
}

.comment {
    margin-top: 30px;
    padding-left: 20px;
    border-left: 1px solid var(--secondary-color);
}

.comment:last-child {
    margin-bottom: 0;
}

.comment-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.comment-author {
    font-size: 14px;
    color: var(--text-secondary-color);
    font-weight: bold;
    margin-right: 10px;
}

.comment-time {
    font-size: 14px;
    color: var(--text-secondary-color);
}

.comment-body {
    font-size: 16px;
    color: var(--text-color);
    line-height: 1.5;
}

.comment-actions {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.comment-actions button {
    background-color: transparent;
    border: none;
    font-size: 14px;
    color: var(--text-secondary-color);
    margin-right: 10px;
    cursor: pointer;
}

.comment-actions button:hover {
    text-decoration: underline;
}

.comment-actions button:focus {
    outline: none;
}

.comment-actions button.active {
    color: var(--primary-color);
}

.comment-votes {
    display: flex;
    align-items: center;
}

.comment-votes button {
    background-color: transparent;
    border: none;
    font-size: 14px;
    color: var(--text-secondary-color);
    margin-right: 10px;
    cursor: pointer;
}

.comment-votes button:hover {
    text-decoration: underline;
}

.comment-votes button:focus {
    outline: none;
}

.comment-votes button.active {
    color: var(--primary-color);
}

.comment-votes .count {
    font-size: 14px;
    color: var(--text-color);
    font-weight: bold;
    margin-right: 10px;
}

.comment-votes .icon {
    font-size: 16px;
    color: var(--text-secondary-color);
}

.comment-form {
    margin-top: 30px;
}

.comment-form textarea {
    width: 100%;
    height: 100px;
    padding: 10px;
    font-size: 16px;
    color: var(--text-color);
    border: 1px solid var(--secondary-color);
    border-radius: 5px;
    resize: none;
}

.comment-form button {
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
}

.comment-form button:hover {
    background-color: var(--primary-color-hover);
}

@media screen and (max-width: 800px) {
    .user-page {
        max-width: 100vw;
        margin-left: 3%;
    }

    .header {
        display: flex;
        flex-direction: column;
    }
}

@media only screen and (max-width: 768px) {
    .post {
        flex-direction: column;
        align-items: center;
    }

    .post-header {
        text-align: center;
    }

    .post-header h1 {
        font-size: 24px;
    }

    .post-header p {
        font-size: 14px;
    }

    .post-body {
        text-align: justify;
    }
}
