.dangerous-content h1              { font-size: 1.75em; margin: .67em 0 }
.dangerous-content h2              { font-size: 1.3em; margin: .75em 0 }
.dangerous-content h3              { font-size: 1.17em; margin: .83em 0 }
.dangerous-content h4,
.dangerous-content blockquote, .dangerous-content ul,
.dangerous-content fieldset, .dangerous-content form,
.dangerous-content ol, .dangerous-content dl, .dangerous-content dir,
.dangerous-content menu            { margin: 0 0 }
.dangerous-content h5              { font-size: .83em; margin: 1.5em 0 }
.dangerous-content h6              { font-size: .75em; margin: 1.67em 0 }

.dangerous-content ul {
    list-style-type: disc;
    list-style-position: inside;
 }
 .dangerous-content ol {
    list-style-type: decimal;
    list-style-position: inside;
 }

 .dangerous-content li {
    margin-left: 15px;
 }

 .notosans > .dangerous-content {
   font-family: "Noto Sans", Arial, sans-serif;
 }

.dangerous-content {
    line-height: 21px;
    color: #1c1c1c;
    letter-spacing: 0.01em;
    margin-left: 6px;
    overflow-wrap: break-word;
    margin: 0;
    max-width: 100%;
}

.notosans > .dangerous-content * {
   font-family: "Noto Sans", Arial, sans-serif;
}

.dangerous-content * {
        overflow-wrap: break-word;
        max-width: 100%;
}

.comment-rte {
   outline: 1px solid black;
   border-radius: 5px;
}

.other-rte {
   outline: 1px solid #80bdff;
   border-color: #80bdff;
   box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
   border-radius: 5px;
}
