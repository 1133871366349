.popup {
    position: fixed;
    bottom: 0%;
    right: 0%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    animation: fadeOut 5s ease-in;
}

.popup h3 {
    font-weight: bold;
    padding-bottom: 10px;
    text-align: center;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    90% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        visibility: hidden;
    }
}
