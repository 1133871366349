/* TODO Add site wide styles */

/* CSS reset */
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
    margin: 0;
    padding: 0;
}
html,
body {
    margin: 0;
    padding: 0;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
fieldset,
img {
    border: 0;
}
input {
    border: 1px solid #b0b0b0;
    padding: 3px 5px 4px;
    color: #979797;
    width: 190px;
}
address,
caption,
cite,
code,
dfn,
th,
var {
    font-style: normal;
    font-weight: normal;
}
ol,
ul {
    list-style: none;
}
caption,
th {
    text-align: left;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: 100%;
    font-weight: normal;
}
q:before,
q:after {
    content: "";
}
abbr,
acronym {
    border: 0;
}

/* CSS reset */

/* %%%%%%%%%% SCROLLBAR STYLING %%%%%%%%%%% */

/* Works on Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: #9d9d9d white;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 18px;
}

*::-webkit-scrollbar-track {
    background: white;
}

*::-webkit-scrollbar-thumb {
    background-color: #c5c5c5;
    border-radius: 20px;
    border: 6px solid #ffffff;
}

/* ================= */

.tooltip {
    position: relative;
    display: inline-block;
}

/* TOOLTIPS */

.tooltip .tooltiptext {
    visibility: hidden;
    width: 80px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    top: 130%;
    left: 50%;
    margin-left: -50px;
    font-size: 12px;
    padding: 7px 10px;
}


.tooltip .tooltiptext::after {
    content: " ";
    position: absolute;
    bottom: 100%;  /* At the top of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
  }

.tooltip:hover .tooltiptext {
    visibility: visible;
}

/* END TOOLTIPS */

* {
    font-family: Verdana, Arial, Helvectica, sans-serif;
}

/* Login/Signup Styling */

.login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 15px 20px 15px 20px;
}

.login-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.form-item {
    padding: 5px 0 5px 0;
}

#submit {
    margin-top: 10px;
    height: 30px;
}

#submit,
#demo,
.input-field {
    width: 300px;
}

#demo {
    margin: 20px 0 10px 0;
    height: 30px;
}

.errors {
    color: red;
    padding: 5px 0 5px 0;
    font-size: 14px;
}

#title {
    padding-bottom: 5px;
    font-size: 18px;
}

/* END LOGIN/SIGNUP STYLE */

.box-dec-1 {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    background-color: white;
    margin: 8px 8px;
    padding: 18px 18px;
    display: flex;
    align-items: center;
}

.box-dec-1:hover {
    outline: 1px solid black;
}

.button-main {
    background: #ff4742;
    transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    border: 1px solid #ff4742;
    /* border-radius: 0.5rem; */
    border-radius: 9999px;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    /* display: inline-block; */
    font-family: nunito, roboto, proxima-nova, "proxima nova", sans-serif;
    /* font-family: -apple-system, system-ui, system-ui, "Segoe UI", Roboto,
    "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans", Cantarell,
    "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Lucida Grande", Helvetica, Arial, sans-serif; */
    font-size: 16px;
    font-weight: 800;
    line-height: 6px;
    min-height: 40px;
    outline: 0;
    /* margin: 10px; */
    /* padding: 15px 30px; */
    text-align: center;
    text-rendering: geometricprecision;
    text-transform: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    font-size: 16px;
    font-weight: 600;
    justify-content: center;
    line-height: 20px;
    max-width: 480px;
    min-height: 30px;
    min-width: 0px;
    overflow: hidden;
    padding: 0px;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: -3px;
    margin-left: 10px;
}

.button-main:hover,
.button-main:active {
    background-color: initial;
    background-position: 0 0;
    color: #ff4742;
}

.button-main:active {
    opacity: 0.5;
}

.button-main:disabled {
    background-color: grey;
    border-color: grey;
    color: white;
    cursor: not-allowed;
}

.adj-button-alt {
    border-radius: 9999px !important;
}

.button-alt {
    background-color: #ffffff;
    border: 1px solid rgb(209, 213, 219);
    transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    border-radius: 0.5rem;
    box-sizing: border-box;
    color: #111827;
    font-family: -apple-system, system-ui, system-ui, "Segoe UI", Roboto,
        "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans", Cantarell,
        "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
        "Lucida Grande", Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 6px;
    /* margin: 10px; */
    padding: 15px 30px;
    text-align: center;
    text-decoration: none #d1d5db solid;
    text-decoration-thickness: auto;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    font-size: 16px;
    /* font-weight: 600; */
    justify-content: center;
    line-height: 20px;
    max-width: 480px;
    min-height: 30px;
    min-width: 0px;
    overflow: hidden;
    padding: 0px;
    padding-left: 20px;
    padding-right: 20px;
}

.button-alt:hover {
    background-color: rgb(249, 250, 251);
}

.button-alt:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
}

.button-alt:focus-visible {
    box-shadow: none;
}

.button-join {
    align-items: center;
    background-color: #0a66c2;
    border: 0;
    border-radius: 100px;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    display: inline-flex;
    font-family: -apple-system, system-ui, system-ui, "Segoe UI", Roboto,
        "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans", Cantarell,
        "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
        "Lucida Grande", Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 600;
    justify-content: center;
    line-height: 20px;
    max-width: 480px;
    min-height: 33px;
    min-width: 0px;
    overflow: hidden;
    padding: 0px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    touch-action: manipulation;
    transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s,
        box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s,
        color 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: middle;
}

.button-join:hover {
    background-color: #16437e;
    color: #ffffff;
}

/* .button-join:active {
    background: #09223b;
    color: rgb(255, 255, 255, 0.7);
} */

.button-join:disabled {
    cursor: not-allowed;
    background: rgba(0, 0, 0, 0.08);
    color: rgba(0, 0, 0, 0.3);
}

.button-leave {
    align-items: center;
    transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    appearance: none;
    background-color: #fff;
    border-radius: 24px;
    border-style: none;
    box-sizing: border-box;
    border: 1px solid #0a66c2;
    color: #0a66c2;
    cursor: pointer;
    display: inline-flex;
    fill: currentcolor;
    font-family: -apple-system, system-ui, system-ui, "Segoe UI", Roboto,
        "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans", Cantarell,
        "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
        "Lucida Grande", Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 600;
    min-height: 33px;
    max-height: 33px;
    justify-content: center;
    letter-spacing: 0.25px;
    line-height: normal;
    max-width: 100%;
    overflow: visible;
    padding: 2px 24px;
    position: relative;
    text-align: center;
    text-transform: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: auto;
    will-change: transform, opacity;
    z-index: 0;
}

.button-leave:hover {
    background: #f6f9fe;
    color: #174ea6;
}

.button-leave:active {
    outline: none;
}

/* .button-leave:focus {
    outline: none;
    border: 1px solid #4285f4;
} */

body {
    padding-top: 48px;
}

#nav-container {
    /* margin: 0px 20px 0px 30px; */
    /* padding: 0px 10px 0px 10px; */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    background-color: white;
    max-height: 48px;
    border-bottom: 1px solid #EDEFF1;
}

#nav-subcontainer {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
}

#main-logo {
    min-width: 30%;
    height: 48px;
    margin-top: 3px;
    justify-self: flex-start;
    display: flex;
}

#nav-svg-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 5px;
}

.subreddit-short-main-container {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    flex-wrap: nowrap;
    padding: 20px 20px;
    background-color: #dae0e6;
    padding-bottom: 70px;
    /* margin-left: 20vw;
    margin-right: 20vw; */
    border-radius: 5px;
    min-height: 84vh;
}

.subreddit-short-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    padding-left: 50px;
    padding-right: 50px;
    max-width: 600px;
    position: relative;
}

.card-title {
    font-weight: bold;
    font-size: 16px;
    padding: 10px 5px 10px 0px;
}

.card-title:hover {
    text-decoration: underline;
}

.card-info {
    padding: 0px 0px 15px;
    font-size: 16px;
    align-self: flex-start;
    font-weight: bold;
    width: 600px;
    margin-top: 20px;
    overflow-wrap: anywhere;
}

.card-category {
    align-self: flex-end;
    font-size: 15px;
    color: grey;
    /* width: 0 auto; */
    margin-right: -25px;
}

.subreddit-preview-img {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    margin-right: 5px;
}

.subreddit-title-preview {
    display: flex;
    align-items: center;
    align-self: flex-start;
}

.subreddit-preview-creator {
    color: grey;
    font-size: 14px;
}

.act-subreddit-btn {
    /* justify-self: flex-end; */
    /* align-self: flex-end; */
    /* margin: 0 auto; */
    position: relative;
    left: 245px;
    bottom: 35px;
}

.card-username-link {
    color: grey;
    text-decoration: none;
}

.card-username-link:hover {
    text-decoration: underline;
}

.subreddit-title-nav {
    text-decoration: inherit;
    color: inherit;
    align-self: flex-start
}

#post-attachment-image {
    max-width: 655px;
    max-height: 700px;
    margin-left: -5px;

    /* height: auto; */
}

#post-prev-attachment-container {
    align-self: flex-start;
}

.subreddit-abridged-top {
    display: flex;
    width: 100%;
}

.remove-bottom-margin {
    margin-bottom: -40px;
}

.topright-avatar-btn {
    background-color: inherit;
    padding: 0px;
    padding-top: 2px;
    border: none;
    color: #bdbdbd;
}

.topright-avatar {
    border-radius: "4px";
    width: "26px";
    height: "26px";
}

.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    z-index: 9999;
    width: 450px;
    max-width: 90%;
    padding: 24px;
    margin-left: 5px;
    max-height: 90vh;
    overflow-y: auto;
}

.modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    border-bottom: 1px solid #e6e6e6;
    padding-bottom: 20px;
}

.modal-header h2 {
    font-size: 24px;
    margin: 0;
    font-family: "Arial";
}

.modal-close-btn {
    background-color: transparent;
    border: none;
    color: #777;
    font-size: 24px;
    cursor: pointer;
}

.modal-close-btn:hover {
    color: #000;
}

.form-group {
    margin-bottom: 20px;
}

.create-comm-label {
    display: block;
    font-weight: 700;
    margin-bottom: 8px;
    font-family: "Arial";
}

.create-comm-input,
.create-comm-input,
.create-comm-input {
    width: 97%;
    max-width: 97%;
    min-height: 18px;
    max-height: 200px;
    font-family: "Arial";
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    color: #333;
    background-color: #f8f8f8;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.create-comm-input:focus,
.create-comm-input:focus,
.create-comm-input:focus {
    outline: none;
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.create-comm-input option {
    font-family: "Arial";
    color: #333;
}

.form-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
}

.btn-primary {
    background-color: #0079d3;
    border: none;
    color: #fff;
    font-size: 16px;
    font-family: "Arial";
    font-weight: 700;
    padding: 8px 16px;
    border-radius: 12px;
    cursor: pointer;
    margin-left: 8px;
    transition: background-color 0.15s ease-in-out;
}

.btn-primary:disabled {
    cursor: not-allowed;
    background-color: rgba(0, 121, 133, 0.2);
}

.btn-primary:not(:disabled):hover {
    background-color: #006bb3;
}

.btn-secondary {
    background-color: #f8f8f8;
    border: 1px solid #e6e6e6;
    color: #333;
    font-size: 16px;
    font-weight: 700;
    padding: 8px 16px;
    font-family: "Arial";
    border-radius: 12px;
    cursor: pointer;
    transition: background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out;
}

.btn-secondary:hover {
    background-color: #e6e6e6;
    border-color: #006bb3;
}

#create-comm-btn {
    margin-left: 10px;
}

/* .modal input[type="submit"] {
    margin-top: 20px;
    padding: 10px;
    background-color: #0079d3;
    color: #fff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }

  .modal input[type="submit"]:hover {
    background-color: #0064ad;
  } */

.user-profile-edit-btn {
    background: inherit;
    border: none;
    cursor: pointer;
}

#subreddit-search {
    display: block;
    width: 650px;
    padding: 16px;
    margin-top: 25px;
    margin-bottom: 25px;
    border: 1px solid #e5e5e5;
    /* border-bottom: 1px solid #e5e5e5; */
    font-size: 16px;
    margin: 0;
    text-align: center;
    /* background-color: #dae0e6; */
    color: black;
    border-radius: 15px;
}

#subreddit-search:hover {
    background-color: white;
    outline: 1px solid #0a66c2;
}

#subreddit-search:focus {
    background-color: white;
    outline: 1px solid #0a66c2;
}

.footer-signup-login {
    margin-top: 15px;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    font-family: "Noto Sans", sans-serif;
    line-height: 16px;
    font-size: 16px;
}

.redirect-signup-login {
    color: #0079d3;
    background: none;
    border: none;
    line-height: 16px;
    font-size: 16px;
    text-decoration: underline;
    cursor: pointer;
}

.toc-signup {
    color: grey;
    font-size: 13px;
}

.toc-links {
    margin-top: 3px;
    color: #0079d3;
    cursor: pointer;
}

.toc-links:hover {
    text-decoration: underline;
}
.subreddit-short-main-container {
    border-radius: 0%;
}

.head-nav-btn-create {
    min-width: 194.41px;
}

.head-nav-btn-create > span {
    min-width: max-content;
}

.third-nav-sec {
    min-width: 30%;
    justify-content: flex-end;
    display: flex;
}

/* .head-nav-btn-explore {
    margin-bottom: 4px;
}

.head-nav-btn {
    margin-top: 0px !important;
}

.head-nav-btn-create {
    margin-top: 6px !important;
} */

/* .head-nav-btn-create {
    margin-top: 4px;
} */

.read-more-btn,
.show-less-btn {
  /* background-color: #007bff; */
  background-color: #eff1f5;
  border: none;
  border-radius: 4px;
  /* color: #fff; */
  color: inherit;
  cursor: pointer;
  /* margin-top: 10px; */
  /* padding: 8px 16px; */
  transition: background-color 0.3s ease-in-out;
  font-size: 16px;
}

.read-more-btn:hover,
.show-less-btn:hover {
  /* background-color: #0069d9; */
}

.show-less-btn {
  margin-left: 15px;
  margin-top: 5px;
}

.truncated-text-container {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
  }

  .truncated-text-container p {
    margin: 0;
    /* width: 90%; */
  }

  .read-more-btn {
    flex-shrink: 0;
    margin-left: 10px;
  }

  .spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  .spinner-container-short {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40vh;
  }

  .spinner {
    border: 5px solid #f3f3f3;
    border-top: 5px solid #3498db;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 2s linear infinite;
  }

  .short-spinner {
    border: 5px solid #f3f3f3;
    border-top: 5px solid #3498db;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin-top: 5vh;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .diff-text {
    font-family: -apple-system, system-ui, system-ui, "Segoe UI", Roboto, "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans", Cantarell, "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Lucida Grande", Helvetica, Arial, sans-serif;
}

.hide-if-big {
    display: none;
}

@media screen and (max-width: 800px) {
    .subreddit-short-container {
        /* margin-left: 1%;
        margin-right: 1%; */
        width: 90vw !important;
        max-width: 70vw;
        /* padding-right: 0px; */
    }

    /* .third-nav-sec {
        min-width: max-content;
        align-items: center;
    } */

    .hide-if-800 {
        display: none;
    }

    .signup-btn-proper {
        margin-top: 1px;
    }

    .subreddit-title-preview {
        /* flex-direction: column; */
        /* justify-content: center;
        align-items: center;
        text-align: center; */
    }

    .subreddit-preview-creator {
        /* text-align: center; */
    }

    .card-category {
        margin-right: 0px;
    }
    .act-subreddit-btn {
        position: revert;
        margin-bottom: 10px;
        margin-top: 15px;
    }

    .card-info {
        width: 100%;
    }
    #subreddit-search {
        width: 100%;
    }
    #post-attachment-image {
        max-width: 70vw;
        max-height: 80vh;

        /* height: auto; */
    }
}

@media screen and (max-width: 700px) {
    .hide-if-big {
        display: revert;
    }
    .subreddit-short-main-container {
        background-color: inherit;
        padding-bottom: 20px;
    }
    .subreddit-abridged-top-short {
        justify-content: center;
    }
    .button-join {
        min-height: 30px !important;
        padding-bottom: 4px !important;
    }

    .head-nav-btn-create {
        min-width: 78px;
        max-width: 78px;
    }

    .hide-if-small {
        display: none;
    }

    .button-leave {
        min-height: 30px !important;
        padding-bottom: 4px !important;
    }
    .subreddit-title-preview {
        /* flex-direction: column; */
    }

    .subreddit-preview-creator {
        text-align: center;
    }

    .login-signup-btn-container {
        display: flex;
        /* flex-direction: column; */
        align-items: center;
        justify-content: center;
    }
    .head-nav-btn {
        padding: 2px 12px;
        /* font-size: 13px; */
        /* font-size: 12px;
        width: 93px; */
        /* width: 80px;  */
        /* height: 38px;
        font-size: 11px;
        margin-left: 0px;
        margin-right: 0px;
        line-height: normal; */
    }

    #nav-container {
        margin: 0px 0px;
    }
    .logout-btn {
        padding-left: 15px;
        padding-right: 15px;
        /* font-size: 13px; */
        /* max-width: 60px;
        line-height: normal;
        font-size: 11px;
        padding-left: 10px;
        padding-right: 10px; */
    }

    .signup-btn-proper span {
        display: block;
        width: 60px;
        min-width: min-content;
    }

    /*

    .logout-btn {
        width: 60px;
        line-height: normal;
        font-size: 12px;
    }



    .head-nav-btn-explore {
        margin-bottom: -12px !important;
    }

    .head-nav-btn-create {
        margin-bottom: 4px;
    } */
}

@media screen and (max-width: 400px) {
    .third-nav-sec {
        margin-right: -10px;
        padding-left: 5px;
    }

}

@media screen and (max-width: 300px) {
    .hide-if-300 {
        display: none;
    }

}
