.content-policy {
    max-width: 800px;
    margin: 0 auto;
    padding: 30px;
    background-color: #FFFFFF;
    border: 1px solid #CCCCCC;
    border-radius: 3px;
    margin-top: 20px;
  }

  .content-policy h2 {
    margin: 0 0 15px;
    font-size: 24px;
    font-weight: bold;
  }

  .content-policy p {
    margin: 0 0 15px;
    line-height: 1.5;
  }

  .content-policy-list {
    margin-top: 30px;
  }

  .content-policy-list h3 {
    margin-bottom: 10px;
    font-weight: bold;
  }

  .content-policy ul {
    list-style-type: disc;
    margin-left: 15px;
    padding-left: 15px;
  }

  .content-policy li {
    margin-bottom: 10px;
  }

  .content-policy-footer {
    margin-top: 30px;
    border-top: 1px solid #CCCCCC;
    padding-top: 30px;
  }

  .content-policy-footer h3 {
    margin: 0 0 15px;
    font-size: 18px;
    font-weight: bold;
  }
