.subreddit-preview {
    position: relative;
    display: inline-block;
    margin: 10px;
}

.hover-preview {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: -1;
    /* display: none; */
    opacity: 0;
    width: 300px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    overflow: hidden;
    transition-duration: 0.1s;
    transition-delay: 0.5s;
}

.hover-preview .hover-icon {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    /* background-color: #0079d3; */
    border-radius: 50%;
}

.hover-preview .hover-info {
    overflow: hidden;
}

.hover-preview .hover-title {
    font-size: 18px;
    font-weight: 700;
    margin: 0;
    line-height: 1.3;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.hover-preview .hover-description {
    font-size: 14px;
    color: #5f5f5f;
    margin: 4px 0 0;
    line-height: 1.3;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.hover-preview .hover-stats {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #5f5f5f;
    margin-top: 10px;
}

.hover-preview .hover-stats .members-count {
    margin-right: 10px;
}

.hover-preview .hover-stats .online-count {
    /* color: #0079d3; */
}

.subreddit-preview:hover .hover-preview {
    /* display: block; */
    opacity: 1;
    z-index: 1;
}

.hover-preview::before {
    content: "";
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #fff transparent;
}

.hover-preview .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 16px;
    color: #5f5f5f;
    cursor: pointer;
}

.hover-preview .close-btn:hover {
    /* color: #0079d3; */
}

.hover-preview .hover-title {
    /* color: #0079d3; */
}

.hover-preview .hover-stats .members-count,
.hover-preview .hover-stats .online-count {
    /* color: #0079d3; */
}

.members-count {
    margin-bottom: 5px;
}


@media screen and (max-width: 600px) {
    .hover-preview {
        display: none;
    }

}
