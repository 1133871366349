#post-comment-upvote {
    align-self: flex-start;
    margin-top: 10px;
    margin-bottom: 0px;
    color: #808080;
    text-decoration: none;
    font-size: 14px;
    /* font-family: "IBMPlexSans" Arial, Helvetica, sans-serif; */
    padding: 4px 4px;
    background-color: inherit;
    cursor: pointer;
}

#post-comment-upvote:hover {
    background-color: rgba(178, 178, 178, 0.3) !important;
}

#post-comment-upvote > i {
    margin-right: 5px;
}

/* .post-short-container {
    margin-bottom: 0px;
    padding-bottom: 0px;
} */

.post-full-container:hover {
    outline: none !important;
}

.voting-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 10px;
}

.upvote-button,
.downvote-button {
    border: none;
    background: transparent;
    cursor: pointer;
    padding: 8px 8px;
}

.upvote-button:hover {
    background-color: rgba(178, 178, 178, 0.2);
    color: #FF4500;
}

.downvote-button:hover {
    background-color: rgba(178, 178, 178, 0.2);
    color: #7193ff;
}

.post-votes {
    font-size: 14px;
    font-weight: bold;
    /* margin: 0; */
}

.has-upvote {
    color: #FF4500;
}

.has-downvote {
    color: #7193ff;
}

.card-votes {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 45px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    /* background-color: rgba(178, 178, 178, 0.2); */
    background-color: #dfdfeb60;
    /* background-color: #f1efef60; */
}

.vote-adj-down {
    margin-top: 15px;
}

.post-prev-adjust-right {
    margin-left: 10px;
}

.post-footer-container {
    align-self: flex-start;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: -10px;
    margin-top: -5px;
}

/* .card-upvote,
.card-downvote {
    margin-bottom: 5px;
} */

/* .post-votes {
    padding-top: 5px;
    padding-bottom: 5px;
} */

/* .downvote-button {
    margin-bottom: 10px;
} */
