.home-section {
    width: 250px;
    /* background-color: #f5f5f5; */
    background-color: #FFF;
    padding: 10px;
    border-radius: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    font-family: -apple-system, system-ui, system-ui, "Segoe UI", Roboto,
    "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans", Cantarell,
    "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Lucida Grande", Helvetica, Arial, sans-serif;
    position: fixed;
    right: calc(0.4vw + 50vw - 660px);
    /* top: 10.5vh; */
    top: 106px;
    /* right: calc(0.4vw + 50vw - 650px); */
  }

  .home-adj-down {
    /* top: 25.5vh; */
    top: 156px;
  }

  .home-adj-far-down {
    top: 38vh;
  }

  .home-header {
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }

  .header-pic {
    width: 100px;
    height: 100px;
    background-color: #ddd;
    border-radius: 50%;
    margin: 0 auto 10px;
  }

  .home-header h2 {
    font-size: 18px;
    /* margin: 0; */
    font-weight: bold;
    color: #333;
    text-align: center;
    font-family: -apple-system, system-ui, system-ui, "Segoe UI", Roboto,
    "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans", Cantarell,
    "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Lucida Grande", Helvetica, Arial, sans-serif;
  }

  .subtitle {
    font-size: 14px;
    color: #777;
    margin-top: 8px;
    text-align: center;
    font-family: -apple-system, system-ui, system-ui, "Segoe UI", Roboto,
    "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans", Cantarell,
    "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Lucida Grande", Helvetica, Arial, sans-serif;
  }

  .home-buttons {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-items: center;
    align-content: center;
    border-bottom: 1px solid #ccc;
    padding-bottom: 15px;
    margin-bottom: 15px;
  }

  .home-extra-icon {
    margin-right: 8px;
  }

  .gh-repo-btn,
  .linkedin-btn {
    margin-bottom: 5px;
    background-color: #0079D3;
    color: #fff;
    border: none;
    border-radius: 3px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: unset;
    line-height: 17px;
    text-transform: unset;
    min-height: 32px;
    text-decoration: none;
    min-width: 32px;
    /* padding: 4px 16px; */
    border-radius: 9999px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: -apple-system, system-ui, system-ui, "Segoe UI", Roboto,
    "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans", Cantarell,
    "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Lucida Grande", Helvetica, Arial, sans-serif;
  }

  .linkedin-btn {
    background-color: #FFF;
    border: 1px solid #0079D3;
    color: #0079D3;
    margin-top: 5px
  }

  .gh-repo-btn:hover {
    background-color: #16437e;
  }

.linkedin-btn:hover {
    background: #f6f9fe;
    color: #174ea6;
}

.home-footer {
    display: flex;
    text-align: center;
    justify-content: space-around;
    padding-bottom: 5px;
}

.home-footer-link {
    font-size: 9px;
    color: #969696;
    text-decoration: none;
}

.home-footer-link:hover {
    text-decoration: underline;
}

@media screen and (max-width: 1329px) {
    .home-section {
        display: none;
    }

}
