/* The Modal (background) */
.modal-delete {
    /* display: none; Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 9999; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content-delete {
    background-color: #ffffff;
    margin: 10% auto; /* 10% from the top and centered */
    padding: 20px;
    border: none;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    /* width: 50%; Could be more or less, depending on screen size */
    max-width: 500px;
    border-radius: 4px;
    font-family: Verdana, Arial, Helvectica, sans-serif;
    color: #333333;
}

/* The Close Button */
.close-delete {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    transition: color 0.3s ease;
    cursor: pointer;
    margin-top: 15px;
}

.close-delete:hover,
.close-delete:focus {
    color: #333333;
    text-decoration: none;
}

.confirm-del-title {
    font-size: 24px;
    margin-bottom: 10px;
    text-align: center;
    font-weight: bold;
    padding: revert;
    margin: revert;
}

.confirm-del-txt {
    margin-bottom: 20px;
    font-size: 18px;
    text-align: center;
    padding: revert;
    margin: revert;
}

.buttons {
    display: flex;
    justify-content: flex-end;
}

.one-button {
    /* margin: revert; */
    padding: 12px 24px;
    margin-left: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 18px;
    transition: background-color 0.3s ease;
}

.one-button#cancelBtn {
    background-color: #eaeaea;
    color: #333333;
}

.one-button#cancelBtn:hover {
    background-color: #d3d3d3;
}

.one-button#confirmBtn {
    background-color: #0077ff;
    color: #ffffff;
}

.one-button#confirmBtn:hover {
    background-color: #0055cc;
}
